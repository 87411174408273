import Image from "next/legacy/image";
import React from 'react'
import { styled } from '../../../../stitches.config'
import { Text } from '../../elements/Text/Text'
import StarRating from './StarRating'

export interface TestimonialsProps {
    showImage: boolean
    imageUrl: string
    testimonialText: string
    textColor: string
    starRating: number
    starColor: string
    unfilledStarColor: string
    starSize: number
    altText: string
}

const Box = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.25rem 1rem',
    maxWidth: '800px',
    img: {
        objectFit: 'scale-down',
    },
})

const Testimonial = ({
    showImage,
    imageUrl,
    testimonialText,
    textColor,
    altText,
    starRating,
    starColor,
    unfilledStarColor,
    starSize,
}: TestimonialsProps) => {
    return (
        <Box>
            {starRating && (
                <StarRating
                    ratingValue={starRating}
                    fillColor={starColor}
                    emptyColor={unfilledStarColor}
                    starSize={starSize}
                />
            )}
            {showImage && (
                <Image src={imageUrl} height={80} width={240} alt={altText} />
            )}
            <Text
                size={4}
                css={{
                    color: textColor,
                    textAlign: 'center',
                    lineHeight: 1.5,
                    margin: 0,
                    '& p': {
                        color: textColor,
                    },
                }}
                dangerouslySetInnerHTML={{ __html: testimonialText }}
            />
        </Box>
    )
}

export default Testimonial
